import React from "react";
import { Route, Routes } from "react-router-dom";

import Login from "./pages/auth/Login";
import RegisterIndex from "./pages/auth/Register";
import LandingPage from "./landingPage/LandingPage";

function App() {
  return (
    <Routes >
      <Route path="/" element={<LandingPage />} />
      <Route path="/auth/login/" element={<Login />} />
      <Route path="/auth/register/" element={<RegisterIndex />} />
    </Routes>
  );
}

export default App;
