import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import certificateIcon from "../assets/certificate.jpg";
import PhoneIcon from "@mui/icons-material/Phone";
import AddressIcon from "@mui/icons-material/LocationCity";
import EmailIcon from "@mui/icons-material/Email";
import Link from "@mui/material/Link";

const tiers = {
  title: "Dr. Muhammad Zarak Awais",
  price: "0",
  description:
    "Drawing upon my background as a health professional working in the United Kingdom, I have seamlessly transitioned into a role that extends beyond the realms of traditional healthcare – that of a student counsellor. With over two years of invaluable experience in counselling, my journey has been marked by a deep commitment to guiding individuals through the multifaceted challenges of their academic endeavours. As a,counsellor, I bring a unique perspective rooted in the principles of health and well-being, enriching my approach with insights gained from navigating the intricacies of the healthcare sector. This introduction sets the stage for an exploration into the intersection of healthcare expertise and counselling, unravelling the profound impact it holds in shaping the educational narratives and overall wellness of the students I have had the privilege to support.",
  buttonText: "Contact US",
  buttonVariant: "outlined",
};

export default function AboutUs() {
  const logoStyle = {
    width: "180px",
    height: "auto",
  };

  return (
    <Container
      id="aboutus"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography component="h2" variant="h4" color="#c32929">
          About Us
        </Typography>
      </Box>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        <Grid item key={tiers.title} xs={12} sm={6} md={12}>
          <Card
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              gap: 1,
              border: undefined,
              borderColor: undefined,
              background: undefined,
            }}
          >
            <Box
              sx={{
                mb: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: tiers.title === "Professional" ? "grey.100" : "",
              }}
            >
              <Typography component="h3" variant="h6" color="#c32929">
                {tiers.title}
              </Typography>
              <Chip
                icon={<AutoAwesomeIcon />}
                label={tiers.subheader}
                size="small"
                sx={{
                  background: (theme) =>
                    theme.palette.mode === "light" ? "" : "none",
                  backgroundColor: "primary.contrastText",
                  "& .MuiChip-label": {
                    color: "primary.dark",
                  },
                  "& .MuiChip-icon": {
                    color: "primary.dark",
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "baseline",
                color: "grey.50",
              }}
            >
              <Box sx={{ width: { xs: "100%", sm: "60%" } }}>
                <Box sx={{ ml: "-0px" }}>
                  <img
                    src={certificateIcon}
                    style={logoStyle}
                    alt="logo of global students"
                  />
                </Box>
              </Box>
            </Box>
            <Divider
              sx={{
                my: 2,
                opacity: 0.2,
                borderColor: "grey.500",
              }}
            />

            <Box
              key={tiers.description}
              sx={{
                py: 1,
                display: "flex",
                gap: 1.5,
                alignItems: "center",
              }}
            >
              <Typography
                component="text"
                variant="subtitle2"
                sx={{
                  color: "grey.200",
                }}
              >
                {tiers.description}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                pt: { xs: 4, sm: 2 },
                width: "100%",
                borderTop: "1px solid",
                borderColor: "divider",
              }}
            >
              <div>
                <EmailIcon sx={{ marginRight: "0.5rem", color: "#003666" }} />
                <Link color="text.secondary" href="#">
                  go.globalstudents.world@gmail.com
                </Link>
                <br />
                <PhoneIcon sx={{ marginRight: "0.5rem",  color: "#003666" }} />
                <Link color="text.secondary" href="#">
                  +447521364939
                </Link>
                <br />
                <AddressIcon sx={{ marginRight: "0.5rem", color: "#003666" }} />
                <Link color="text.secondary" href="https://maps.app.goo.gl/8UnvXYSkduqu42oq6">
                  Plaza no 217-A, first floor, ferozpur road Lahore Central Park
                  housing society, Post code 54600
                </Link>
              </div>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
