import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
// import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        color="#c32929"
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        Frequently asked questions
      </Typography>
      <Box sx={{ width: "100%" }}>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography component="h3" variant="subtitle2">
              Why study abroad?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: "100%", md: "70%" } }}
            >
              There are many reasons why study abroad programs are becoming so
              popular. For most international students, the appeal is likely to
              be a combination of gaining a high-quality education, experiencing
              immersion in a new culture (and often a second language), gaining
              a global mindset and expanding future employment prospects. For
              some, the prospect of leaving home and heading off into the
              unknown is daunting but studying abroad is also an exciting
              challenge that often leads to improved career opportunities and a
              broader understanding of the way the world works. The type of
              experience you have during your time abroad will vary hugely
              depending on where you go, so make sure to pick a study
              destination based on your own personal interests as well as the
              country’s academic reputation, in order to keep a healthy
              work/play balance.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <Typography component="h3" variant="subtitle2">
              Is it possible to study in UK without IELTS?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: "100%", md: "70%" } }}
            >
              Yes, you can apply for UK universities without IELTS. But, it
              depends on satisfying few entry criteria/conditions. Every
              university will have their own set of entry requirements and
              procedures. If you have scored more than 65% of marks in your
              Standard 12th/Intermediate/Higher Secondary/CBSE English subject
              then you may be considered for some universities without IELTS.
              Some universities may ask for 70% or 80% of marks in Standard 12th
              English subject. Some will only consider CBSE students. If you do
              not have 65% or above then you may need to take IELTS or any
              acceptable English language exams to get admissions in the UK
              universities. In some case even if you have secured more than 65%
              or 80% in your standard 12th English subject but having an
              academic gap of more than 5 years then the university may not
              accept you without IELTS or any other English Language exams. For
              some universities IELTS is mandatory irrespective of your Standard
              12th English Subject marks or gap years or medium of study.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Typography component="h3" variant="subtitle2">
              When is the best time to study abroad?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: "100%", md: "70%" } }}
            >
              You can start your overseas education process, once you complete
              your HSC or Under graduation.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4d-content"
            id="panel4d-header"
          >
            <Typography component="h3" variant="subtitle2">
              Can you suggest low cost universities in UK for international
              students?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: "100%", md: "70%" } }}
            >
              Many universities provide courses with less tuition fee when
              compared with some of the universities in the UK. We will suggest
              universities based on your preferences only. However, you will be
              able to apply to the universities only if you are eligible.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
}
