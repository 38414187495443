import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import bgImg from "../assets/student-bg.jpg";

export default function Hero() {
  return (
    <Box
      id="hero"
      sx={{
        width: "100%",
        backgroundImage: `url(${bgImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 14, sm: 15 },
          pb: { xs: 8, sm: 10 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: "100%", sm: "70%" } }}>
          <Typography
            component="h1"
            variant="h1"
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignSelf: "center",
              textAlign: "center",
              color: "#c32929",
            }}
          >
            Global&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={{
                color: "#003666",
              }}
            >
              Students
            </Typography>
          </Typography>
          <Typography variant="body1" textAlign="justify" fontWeight={600} fontSize={16}
          sx={{
            pt: { xs: 10, sm: 0 },
            pb: { xs: 10, sm: 30 },
            color: { xs: "#fff", sm: "#003666" },
          }}
         >
            Through our university partnerships and innovative face-to-face,
            online, and hybrid study programmes, Global Students guide thousands
            of international students each year towards academic and
            professional success – creating opportunities for the next
            generation of industry experts. Our courses range from pathways to
            undergraduate and postgraduate study delivered in partnership with
            universities across the world. Our Collaboration with Edumiles
            benefits students to get access to more than 1500
            universities Worldwide.
          </Typography>
          {/* <Stack
            direction={{ xs: "column", sm: "row" }}
            alignSelf="center"
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: "100%", sm: "auto" } }}
          >
            <TextField
              id="outlined-basic"
              hiddenLabel
              size="small"
              variant="outlined"
              aria-label="Enter your email address"
              placeholder="Your email address"
              inputProps={{
                autocomplete: "off",
                ariaLabel: "Enter your email address",
              }}
            />
            <Button variant="contained" color="primary">
              Start now
            </Button>
          </Stack> */}
          {/* <Typography
            variant="caption"
            textAlign="center"
            fontSize={15}
            sx={{ color: "#ffffff" }}
          >
            By clicking &quot;Start now&quot; you agree to our&nbsp;
            <Link href="#" >
              Terms & Conditions
            </Link>
            .
          </Typography> */}
        </Stack>
      </Container>
    </Box>
  );
}
